<template>

	<TopMenu :breadcrumbs="breadcrumbs"/>
	
	<Navigator />

	<div class="component-filter">
		<div class="range-picker">
			<DatePicker v-model="filter.begin" label="Дата от" :isClear="false" />
			<DatePicker v-model="filter.end" label="Дата до" :isClear="false" />
		</div>
		<div class="btn-group">
			<a class="btn btn--medium btn--default btn--inline" @click="saveFilter($event)">
				<div class="btn--text"> Применить фильтры </div>
				<div class="btn--loader"> </div>
			</a>
			<a class="btn btn--medium btn--outline btn--inline" @click="clearFilter">
				<div class="btn--text"> Сбросить фильтры </div>
				<div class="btn--loader"> </div>
			</a>
		</div>
	</div>

	<div class="load-container" :class="{'load' : isLoad}">

	  <CollapseContainer 
	  	v-for="item in modules" 
	  	:key="item.moduleId" 
	  	class="collapse--noborder"
	  	ref="module"
	  >

	    <template v-slot:header>
	    	<div class="c-ico" :style="{background: iconColor(item.state).bg}">
					<component :is="item.num" :color="iconColor(item.state).color"></component>
				</div>
				{{ item.name }} &nbsp; <span :style="{'color': iconColor(item.state).color}"> {{ item.state + '%' }} </span>
	    </template>
	    
	    <template v-slot:body>
	    	<div class="tabs">
					<div class="tabs__header">
						<div 
							class="tabs__item" 
							:class="{'tabs__item--active': state[item.num].action == 'review'}"
							@click="state[item.num].action = 'review'">
							Осмотры
						</div>
						<div 
							class="tabs__item"
							:class="{'tabs__item--active': state[item.num].action == 'work'}"
							@click="state[item.num].action = 'work'">
							Работы
						</div>
					</div>
					<div class="tabs__body">
						<StatModuleSystemReview
							:module_id="item.moduleId"
							:house_id="house_id"
							:begin="filter.begin"
							:end="filter.end"
							v-if="state[item.num].action == 'review'"/>
						<StatModuleSystemWork
							:module_id="item.moduleId"
							:house_id="house_id"
							:begin="filter.begin"
							:end="filter.end"
							v-if="state[item.num].action == 'work'"/>
					</div>
				</div>
	    </template>
	  </CollapseContainer>

	</div>

</template>

<script setup>

	import TopMenu from '@/components/TopMenu.vue';
	import Navigator from '@/components/reports/Navigator.vue';
	import DatePicker from '@/components/ux/DatePicker.vue';
  import CollapseContainer from '@/components/ux/CollapseContainer.vue'

	import StatModuleSystemReview from '@/components/reports/system/StatModuleSystemReview.vue';
	import StatModuleSystemWork from '@/components/reports/system/StatModuleSystemWork.vue';

  import A from '@/components/icons/modules/A.vue';
  import B from '@/components/icons/modules/B.vue';
  import C from '@/components/icons/modules/C.vue';
  import D from '@/components/icons/modules/D.vue';
  import E from '@/components/icons/modules/E.vue';
  import F from '@/components/icons/modules/F.vue';
  import G from '@/components/icons/modules/G.vue';
  import H from '@/components/icons/modules/H.vue';

</script>

<script>

  export default {

    name: 'PageReportsSystemHouse',

    components: { A, B, C, D, E, F, G, H },

    data: () => ({

      	isLoad: false,
      	
      	breadcrumbs: [],
      	
      	house: null,

      	modules: [],

      	state: {
      		A: { action: 'review' },
      		B: { action: 'review' },
      		C: { action: 'review' },
      		D: { action: 'review' },
      		E: { action: 'review' },
      		F: { action: 'review' },
      		G: { action: 'review' },
      		H: { action: 'review' },
      	},

      	filter: {
	      	begin: null,
	      	end: null,
      	},

    }),

    computed: {
    	house_id () {
    		return this.$route.params.house_id;
    	},
    	iconColor () {
    		return (percent) => {
    			if(percent >= 90) {
    				return {color: 'rgba(var(--cozh-green-code), 1)', bg: 'rgba(var(--cozh-green-code), .08)'};
    			}
					if(percent < 90 && percent > 70) {
    				return {color: 'rgba(var(--cozh-orange-code), 1)', bg: 'rgba(var(--cozh-orange-code), .08)'};
					}
					if(percent <= 70) {
    				return {color: 'rgba(var(--cozh-red-code), 1)', bg: 'rgba(var(--cozh-red-code), .08)'};
					}
    		}
    	},
    },

    created () {

    	this.filter.begin = this.$moment().subtract(7, 'days').format('YYYY-MM-DD');
    	this.filter.end = this.$moment().format('YYYY-MM-DD');

    	Promise.all([this.loadHouse()])
		    .then(() => {
		      	this.beforeRender();
		    })
		    .catch((e)=> {
		      	console.log(e);
		    })

		this.loadData();
    },
    methods: {
    	beforeRender () {
    		this.breadcrumbs = [
	        	{name: this.$route.meta.name, link: this.$route.matched[0].path, ico: this.$route.meta.ico},
	        	{name: this.house.address, link: ''},
	        ];
    	},

    	async clearFilter (e) {
		 		const btn = e.currentTarget;
		 		btn.classList.add('btn--load');
	    		this.filter.begin = this.$moment().subtract(7, 'days').format('YYYY-MM-DD');
	    		this.filter.end = this.$moment().format('YYYY-MM-DD');
	    		await this.loadData();
		 		btn.classList.remove('btn--load');
    	},

    	async saveFilter (e) {
		 		const btn = e.currentTarget;
		 		btn.classList.add('btn--load');
	    	await this.loadData();
		 		btn.classList.remove('btn--load');
    	},

    	buildfilter () {
    		const param = new URLSearchParams({});
    		if(this.filter.begin !== null) {
      		param.set("begin", this.filter.begin + 'T00:00:00.802333+00:00');
        }
        if(this.filter.end !== null) {
        	param.set("end", this.filter.end + 'T23:59:59.802443+00:00');
        }
        return param;
    	},

    	async loadData () {

    		this.isLoad = true;

      	// for (let num in this.state) {
      	// 	this.state[num].open = false;
      	// }

      	// При обновлении списка модулей, закрываем все их колапсы
    		if(this.$refs.module) {
    			this.$refs.module.forEach((module) => {
    				module.isOpen = false;
    			});
    		}

      	const param = this.buildfilter();
      	const {modules} = await this.$store.dispatch('getAnaliticsSystemModules', {house_id: this.house_id, data: param});
      	this.modules = [];
      	for (let id in modules) {
      		this.modules.push(modules[id]);
      	}
    		this.isLoad = false;

    		


    	},

    	async loadHouse () {
      		this.house = await this.$store.dispatch('getHouse', {house_id: this.house_id});
    	},
    }
  }
</script>

<style scoped>

	.collapse:not(:last-child) {
		margin-bottom: 5px;
	}

	.collapse:deep(.collapse__header) {
		padding: 10px;
		font-weight: 500;
	}

	.collapse:deep(.collapse__body) {
		padding: 0px;
	}

	.c-ico {
		height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #E9F1F4;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
	}



	.component-filter {
		background-color: #fff;
		padding: 15px;
		border-radius: 8px;
		display: flex;
    	flex-direction: row;
    	align-items: center;
    	gap: 10px;
    	justify-content: space-between;
    	position: relative;
    	margin-bottom: 10px;
	}

	.component-filter__item {
		flex: 1 1 0;
  		width: 0;
	}
	.component-filter a {
		width: fit-content;
	}

	


	.tabs {

	}

	.tabs__header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		height: 50px;
		padding: 0px 20px;
	}
	.tabs__body {
		border-top: 1px solid var(--cozh-light-grey);
		padding: 20px;
	}

	.tabs__item {
		font-size: 11px;
		font-weight: 600;
		color: var(--cozh-grey);
		text-transform: uppercase;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		border-bottom: 4px solid transparent;
		transition: all .2s ease-out;
		cursor: pointer;
		user-select: none;
	}
	.tabs__item:not(:last-child){
		margin-right: 30px;
	}

	.tabs__item--active {
		color: var(--cozh-black);
		border-color: var(--cozh-black);
	}

	.tabs__item:hover {
		color: var(--cozh-balck);
	}



</style>
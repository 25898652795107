<template>
    <div class="collapse" :class="{'open': isOpen}">
        <div class="collapse__header" @click="clickHeader($event)" ref="header">
            <slot name="header"></slot>
        </div>
        <transition-expand>
            <div class="collapse__content" v-if="isOpen" ref="content">
                <div class="collapse__body">
                    <slot name="body"></slot>
                </div>
            </div>
        </transition-expand>
    </div>
</template>

<script setup>
    import TransitionExpand from '@/components/ux/TransitionExpand.vue';
</script>


<script>
    export default {
        name: 'CollapseContainer',
        // Публично доступный флаг
        expose: ['isOpen'],
        props: ['size'],
        data: () => ({
            isOpen: false,
        }),
        methods: {
            clickHeader (e) {
                
                if(e.target.closest('.c-collapse__buttons') !== null || e.target.tagName == 'INPUT') { return; }

                if(!this.isOpen) {
                    const collapse = e.target.closest(".collapse");
                    if(collapse.classList.contains('collapse--disabled')) { return; }
                }

                this.isOpen = !this.isOpen;
            },
        },
        computed: {
        }
    }
</script>


<style scoped>
    .collapse {
        border: 1px solid var(--cozh-light-grey);
        border-radius: 8px;
        width: 100%;
    }
    .collapse__header {
        font-size: 18px;
        font-weight: 600;
        color: var(--cozh-black);
        min-height: 60px;
        padding: 15px 20px;
        padding-right: 60px;
        cursor: pointer;
        position: relative;
        user-select: none;
        transition: all .2s ease-out;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        scroll-margin: 20px;
    }
    .collapse__header::before {
        content: '';
        background-image: url('@/assets/img/collapse-black-ico.svg');
        position: absolute;
        right: 27px;
        top: 50%;
        transform: translateY(-50%) rotate(-180deg);
        height: 5px;
        width: 8px;
        transition: all .2s ease-out;
    }
    .collapse__body {
        padding: 20px 20px;
        border-top: 1px solid var(--cozh-light-grey);
    }
    .collapse__footer {
        padding: 20px 20px;
        border-top: 1px solid var(--cozh-light-grey);
    }
    .collapse.open > .collapse__header::before {
        transform: translateY(-50%) rotate(0deg);
        background-image: url('@/assets/img/collapse-blue-ico.svg');
    }
    .collapse.open:not(:last-child) {
        margin-bottom: 30px;
    }







    .collapse--noborder {
        background-color: var(--cozh-white);
        border: none;
    }
    .collapse--noborder .collapse__header {
        font-size: 18px;
        font-weight: 600;
    }
    .collapse--noborder .collapse__header::before {
        background-image: url('@/assets/img/collapse-grey-ico.svg');
    }



    .collapse--transparent {
        border: none;
    }
    .collapse--transparent .collapse__header {
        background-color: var(--cozh-white);
        border-radius: 8px;
    }

    .collapse--transparent .collapse__header::before {
        background-image: url('@/assets//img/collapse-grey-ico.svg');
    }

    .collapse--transparent .collapse__body {
        border-top: none;
        padding: 5px 0px 0px 20px;
    }




    .collapse--sm .collapse__header {
        font-size: 14px;
    }

</style>